import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Formik, getIn } from "formik";
import Select from "react-select";
import _find from "lodash/find";
import * as Yup from "yup";
import { Form, Row, Col, Container, Button, Spinner, Card } from "react-bootstrap";
import I18n from "../../utils/i18n";
import TopBarWithBackLink from "../../common/components/TopBarWithBackLink";
import NavigationPrompt from "../../common/components/NavigationPrompt";
import { getModels, getPrompt, savePrompt } from "../../api/aiAPI";
import { setAlert } from "../../state/alert/alertActions";
import TooltipComponent from "../../common/components/TooltipComponent";
import { MessageControl, RangeControl } from "./components/controls";

const AIPromptPage = () => {
    const backPath = "/ai/prompts";
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [prompt, setPrompt] = useState({});
    const [isModelsLoading, setIsModelsLoading] = useState(true);
    const [models, setModels] = useState([]);

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        savePrompt(values)
            .then((response) => {
                setPrompt(response.data);
                dispatch(setAlert({ translationKey: "common.alerts.successful_save", variant: "info" }));
                setSubmitting(false);
                history.push(backPath);
            })
            .catch((error) => {
                error.response.status === 422 && setErrors(error.response.data);
                dispatch(setAlert({ translationKey: "common.alerts.failed_save", variant: "danger" }));
                setSubmitting(false);
            });
    };

    const validationSchema = Yup.object().shape({
        prompt: Yup.object().shape({
            name: Yup.string().max(255).required().nullable(),
            uid: Yup.string().max(255).required().nullable(),
            message: Yup.string().max(4096).required().nullable(),
            temperature: Yup.number().min(0).max(1).required(),
            top_p: Yup.number().min(0).max(1).required(),
            max_tokens: Yup.number().min(1).max(4096).required(),
            ai_model_id: Yup.number().required().nullable(),
        }),
    });

    useEffect(() => {
        getPrompt(id).then((response) => {
            setPrompt(response.data);
        });

        getModels()
            .then((response) => {
                setModels(response.data);
            })
            .finally(() => setIsModelsLoading(false));
    }, []);

    return (
        <>
            <TopBarWithBackLink backPath={backPath} linkName={I18n.t("sidebar.links.manage.ai_prompts")} />
            <div className="scrollable d-flex flex-column">
                <Formik
                    enableReinitialize
                    initialValues={{ prompt: prompt }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, dirty }) => (
                        <>
                            <NavigationPrompt when={dirty} />
                            <div className="flex-grow-1 overflow-auto">
                                <Container fluid className="h-100">
                                    <Row className="h-100">
                                        <Col>
                                            <MessageControl />
                                        </Col>
                                        <Col md={4} className="pl-0">
                                            <Card className="h-100">
                                                <Card.Body>
                                                    <Row>
                                                        <Col>
                                                            <Form.Label column="name">
                                                                {I18n.t("activerecord.attributes.ai/prompt.name")}
                                                            </Form.Label>
                                                            <Form.Control
                                                                placeholder={I18n.t("ai/prompt.name.placeholder")}
                                                                type="text"
                                                                name="prompt[name]"
                                                                value={values.prompt.name || ""}
                                                                onChange={handleChange}
                                                                isInvalid={getIn(errors, "prompt.name")}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {getIn(errors, "prompt.name")}
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <Form.Label column="name">
                                                                {I18n.t("activerecord.attributes.ai/prompt.uid")}
                                                                <TooltipComponent
                                                                    content={I18n.t("ai/prompt.uid.tooltip")}
                                                                />
                                                            </Form.Label>
                                                            <Form.Control
                                                                placeholder={I18n.t("ai/prompt.uid.placeholder")}
                                                                type="text"
                                                                name="prompt[uid]"
                                                                value={values.prompt.uid || ""}
                                                                onChange={handleChange}
                                                                disabled={id !== undefined}
                                                                isInvalid={getIn(errors, "prompt.uid")}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {getIn(errors, "prompt.uid")}
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <Form.Label column="model">
                                                                {I18n.t(
                                                                    "activerecord.attributes.ai/prompt.ai_model_id",
                                                                )}
                                                            </Form.Label>
                                                            <Select
                                                                className={classNames("react-select", {
                                                                    "is-invalid": getIn(errors, "prompt.ai_model_id"),
                                                                })}
                                                                classNamePrefix="react-select"
                                                                name="prompt[ai_model_id]"
                                                                options={models}
                                                                value={_find(models, ["id", values.prompt.ai_model_id])}
                                                                getOptionValue={(model) => model.id}
                                                                getOptionLabel={(model) => model.name}
                                                                onChange={(model) =>
                                                                    setFieldValue("prompt[ai_model_id]", model.id)
                                                                }
                                                                isLoading={isModelsLoading}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {getIn(errors, "prompt.ai_model_id")}
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Row>

                                                    <RangeControl name="temperature" min={0} max={1} step={0.1} />
                                                    <RangeControl name="max_tokens" min={1} max={4096} step={1} />
                                                    <RangeControl name="top_p" min={0} max={1} step={0.1} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className="bottom-navigation p-2">
                                <Col>
                                    <Container fluid>
                                        <div className="d-flex justify-content-between">
                                            <div className="form-actions">
                                                <Button
                                                    className="ml-2 text-white"
                                                    variant="primary"
                                                    disabled={isSubmitting}
                                                    onClick={handleSubmit}
                                                >
                                                    {isSubmitting && (
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className="mr-2"
                                                        />
                                                    )}
                                                    {I18n.t("common.links.save")}
                                                </Button>
                                            </div>
                                        </div>
                                    </Container>
                                </Col>
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default AIPromptPage;
