import { I18n } from "i18n-js";
import * as Yup from "yup";
import moment from "moment";
import en from "../../locales/en.json";
import enUS from "../../locales/en-US.json";
import es from "../../locales/es.json";
import esMX from "../../locales/es-MX.json";
import zh from "../../locales/zh.json";
import zhCN from "../../locales/zh-CN.json";

const i18n = new I18n({ ...en, ...enUS, ...es, ...esMX, ...zh, ...zhCN });
i18n.defaultLocale = "en-US";
i18n.enableFallback = true;
i18n.placeholder = /%\{(.*?)\}/gm;
const dateFormat = "MM-DD-YYYY hh:mm a";

export const updateLocale = (locale) => {
    i18n.locale = locale;
    document.cookie = `locale=${locale}; path=/; Secure; SameSite=Strict;`;

    Yup.setLocale({
        mixed: {
            default: () => i18n.t("errors.messages.invalid"),
            required: () => i18n.t("errors.messages.blank"),
            notType: ({ type }) => i18n.t(`errors.messages.not_a_${type}`),
        },
        string: {
            length: ({ length }) => i18n.t("errors.messages.wrong_length", { count: length }),
            min: ({ min }) => i18n.t("errors.messages.too_short", { count: min }),
            max: ({ max }) => i18n.t("errors.messages.too_long", { count: max }),
            email: () => i18n.t("errors.messages.invalid"),
            url: () => i18n.t("errors.messages.invalid"),
        },
        date: {
            min: ({ min }) =>
                i18n.t("errors.messages.greater_than_or_equal_to", { count: moment(min).format(dateFormat) }),
            max: ({ max }) =>
                i18n.t("errors.messages.less_than_or_equal_to", { count: moment(max).format(dateFormat) }),
        },
        number: {
            min: ({ min }) => i18n.t("errors.messages.greater_than_or_equal_to", { count: min }),
            max: ({ max }) => i18n.t("errors.messages.less_than_or_equal_to", { count: max }),
            lessThan: ({ less }) => i18n.t("errors.messages.less_than", { count: less }),
            moreThan: ({ more }) => i18n.t("errors.messages.greater_than", { count: more }),
            notEqual: ({ notEqual }) => i18n.t("errors.messages.must_be_equal_to", { count: notEqual }),
            positive: () => i18n.t("errors.messages.greater_than", { count: 0 }),
            negative: () => i18n.t("errors.messages.less_than", { count: 0 }),
            integer: () => i18n.t("errors.messages.not_an_integer"),
        },
    });
};

updateLocale(document.querySelector("html").getAttribute("lang"));

export default i18n;
