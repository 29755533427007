import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Container, Col, FormCheck, FormLabel, Row, Button, FormControl } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import _isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import I18n from "../../../utils/i18n";
import currencies from "../../../utils/currencies";
import FileContainer from "../../../common/components/FileContainer";
import { uploadSuppliers } from "../../../api/tieredDiversityApi";
import TooltipComponent from "../../../common/components/TooltipComponent";
import { convertMomentToQuarterFormat } from "../../../utils/diversityQuarters";
import useChannel from "../../../utils/channels/useChannel";

const UploadStep = ({
    format,
    setFormat,
    period,
    setCurrentStep,
    setUploadResults,
    currency,
    setCurrency,
    revenue,
    setRevenue,
    validationErrors,
    setValidationErrors,
}) => {
    const currentUser = useSelector((state) => state.currentUser.resource);
    const history = useHistory();
    const [total, setTotal] = useState(0);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [uploadErrors, setUploadErrors] = useState([]);

    let validationSchema = Yup.object().shape({
        format: Yup.string(),
        total: Yup.number().when("format", {
            is: "percentage",
            then: (schema) => schema.min(0).required(),
        }),
        revenue: Yup.number().min(0).required(),
        currency: Yup.string().required(),
    });

    useEffect(() => {
        validationSchema
            .validate({ format, total, revenue, currency })
            .then(() => {
                setValidationErrors({});
            })
            .catch((error) => setValidationErrors((prevState) => ({ ...prevState, [error.path]: error.errors })));
    }, [format, total, revenue, currency]);

    useChannel({
        channelParams: { channel: "TieredDiversity::UploadsChannel" },
        onReceived: ({ type, payload: { results, errors } }) => {
            if (type === "finished") {
                setUploadResults(results);
                setUploadErrors(errors);
                setUploadInProgress(false);
                errors.length > 0 || setCurrentStep((prevState) => prevState + 1);
            }
        },
    });

    return (
        <Container fluid className="d-flex flex-column py-3">
            <Row className="pt-3 pb-2 border-bottom">
                <h2>{I18n.t("tiered_diversity.suppliers.new.steps.upload.title")}</h2>
            </Row>
            <Row className="flex-grow-1">
                <Col className="p-3">
                    <Row className="mb-3">
                        <Col md={4} className="d-flex">
                            <FormLabel>{I18n.t("tiered_diversity.suppliers.new.steps.upload.format.label")}</FormLabel>
                            <TooltipComponent
                                content={I18n.t("tiered_diversity.suppliers.new.steps.upload.format.tooltips.label")}
                            />
                        </Col>
                        <Col>
                            <div className="d-flex">
                                <FormCheck
                                    type="radio"
                                    label={I18n.t("tiered_diversity.suppliers.new.steps.upload.format.amount")}
                                    name="format"
                                    id="format_amount"
                                    value="amount"
                                    checked={format === "amount"}
                                    onChange={(event) => setFormat(event.target.value)}
                                />
                                <TooltipComponent
                                    content={I18n.t(
                                        "tiered_diversity.suppliers.new.steps.upload.format.tooltips.amount",
                                    )}
                                />
                            </div>
                            <div className="d-flex">
                                <FormCheck
                                    type="radio"
                                    label={I18n.t("tiered_diversity.suppliers.new.steps.upload.format.percentage")}
                                    name="format"
                                    id="format_percentage"
                                    value="percentage"
                                    checked={format === "percentage"}
                                    onChange={(event) => setFormat(event.target.value)}
                                />
                                <TooltipComponent
                                    content={I18n.t(
                                        "tiered_diversity.suppliers.new.steps.upload.format.tooltips.percentage",
                                    )}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={4}>
                            <FormLabel htmlFor="total_currency">
                                {I18n.t("tiered_diversity.excel.amount.header.total_currency")}
                            </FormLabel>
                        </Col>
                        <Col md={2}>
                            <Select
                                name="total_currency"
                                className="react-select"
                                classNamePrefix="react-select"
                                options={currencies}
                                getOptionLabel={(option) =>
                                    option.iso_code === "XXX"
                                        ? option.name
                                        : `${option.symbol} (${option.iso_code} (${option.name}))`
                                }
                                getOptionValue={(option) => option.iso_code}
                                onChange={(option) => {
                                    setCurrency(option.iso_code);
                                }}
                                value={currencies.find((option) => option.iso_code === currency)}
                                isInvalid={validationErrors.currency}
                            />
                            <FormControl.Feedback type="invalid">{validationErrors.currency}</FormControl.Feedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row className="mb-3">
                                <Col md={4} className="d-flex">
                                    <FormLabel htmlFor="revenue_amount">
                                        {I18n.t("tiered_diversity.excel.amount.header.revenue_amount")}
                                    </FormLabel>
                                </Col>
                                <Col md={2}>
                                    <FormControl
                                        id="revenue_amount"
                                        type="number"
                                        onChange={(event) => setRevenue(event.target.value)}
                                        value={revenue}
                                        isInvalid={validationErrors.revenue}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {validationErrors.revenue}
                                    </FormControl.Feedback>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {format === "percentage" && (
                        <Row>
                            <Col>
                                <Row className="mb-3">
                                    <Col md={4} className="d-flex">
                                        <FormLabel htmlFor="total_amount">
                                            {I18n.t("tiered_diversity.excel.amount.header.total_amount")}
                                        </FormLabel>
                                        <TooltipComponent
                                            content={I18n.t("tiered_diversity.excel.amount.header.tooltip")}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <FormControl
                                            id="total_amount"
                                            type="number"
                                            onChange={(event) => setTotal(event.target.value)}
                                            value={total}
                                            isInvalid={validationErrors.total}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {validationErrors.total}
                                        </FormControl.Feedback>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                    <Row className="mt-5 mb-3">
                        <Col>
                            <h2>
                                {I18n.t("diversity.quarters.date_range", {
                                    start_date: convertMomentToQuarterFormat(
                                        moment.unix(period.from).tz(currentUser.time_zone),
                                    ),
                                    end_date: convertMomentToQuarterFormat(
                                        moment.unix(period.to).tz(currentUser.time_zone),
                                    ),
                                })}
                            </h2>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p>{I18n.t("tiered_diversity.suppliers.new.steps.upload.steps.first.label")}</p>
                            <Button
                                variant="primary"
                                className="text-white"
                                href={`/tiered_diversity/uploads/new.xlsx?type=${format}`}
                                target="_blank"
                            >
                                {I18n.t("tiered_diversity.suppliers.new.steps.upload.steps.first.buttons.download")}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p>{I18n.t("tiered_diversity.suppliers.new.steps.upload.steps.second.label")}</p>

                            {uploadInProgress ? (
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only" />
                                    </div>
                                </div>
                            ) : (
                                <FileContainer
                                    accept={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                    disabled={!_isEmpty(validationErrors)}
                                    onSuccess={(acceptedFiles) => {
                                        setUploadInProgress(true);
                                        setUploadErrors([]);

                                        let params = {
                                            type: format,
                                            file: acceptedFiles[0],
                                        };

                                        if (format === "percentage") {
                                            params["total_amount"] = total;
                                            params["total_currency"] = currency;
                                        }

                                        uploadSuppliers(params).catch((error) => {
                                            if (error.response.status === 403) {
                                                history.push("/");
                                            } else {
                                                setUploadErrors([I18n.t("diversity.excel.errors.common")]);
                                                setUploadResults([]);
                                                setUploadInProgress(false);
                                            }
                                        });
                                    }}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p>{I18n.t("tiered_diversity.suppliers.new.steps.upload.steps.third.label")}</p>

                            <div className="overflow-auto mt-3">
                                {uploadErrors.map((uploadError, index) => (
                                    <p key={index} className="text-danger">
                                        {uploadError}
                                    </p>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

UploadStep.propTypes = {
    format: PropTypes.string.isRequired,
    setFormat: PropTypes.func.isRequired,
    period: PropTypes.shape({
        from: PropTypes.number.isRequired,
        to: PropTypes.number.isRequired,
    }),
    setCurrentStep: PropTypes.func.isRequired,
    setUploadResults: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired,
    setCurrency: PropTypes.func.isRequired,
    revenue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    setRevenue: PropTypes.func.isRequired,
    validationErrors: PropTypes.shape({
        total: PropTypes.arrayOf(PropTypes.string),
        revenue: PropTypes.arrayOf(PropTypes.string),
        currency: PropTypes.arrayOf(PropTypes.string),
    }),
    setValidationErrors: PropTypes.func.isRequired,
};

export default UploadStep;
