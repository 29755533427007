import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Card, Row, Col, Table as BootstrapTable } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import _toString from "lodash/toString";
import I18n from "../../../utils/i18n";
import Table from "../../../common/components/Table";
import { getRequests } from "../../../api/aiAPI";
import { sanitizeText } from "../../../utils/sanitizeText";

const ExpanderCell = ({ row }) => (
    <span {...row.getToggleRowExpandedProps()} className={classNames("expand", { down: row.isExpanded })}>
        <FontAwesomeIcon icon={faChevronRight} />
    </span>
);

const ActorNameCell = ({
    row: {
        original: { user_email, user_name },
    },
}) => (
    <div className="d-flex flex-column">
        {user_name}
        <small className="text-muted">{user_email}</small>
    </div>
);

const OrganizationNameCell = ({
    row: {
        original: { organization_name, organization_id },
    },
}) => (
    <div className="d-flex flex-column">
        {organization_name}
        <small className="text-muted">{organization_id}</small>
    </div>
);

const CreatedAtCell = ({ row: { original } }) => (
    <div className="d-flex flex-column">{I18n.l("time.formats.long", original.created_at)}</div>
);

const PROMPT_FIELDS = ["message", "ai_model_uid", "temperature", "top_p", "max_tokens"];
const RESPONSE_FIELDS = [];

const RequestsTable = () => {
    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: "expander",
                isSorted: false,
                Cell: ExpanderCell,
            },
            {
                Header: I18n.t("activerecord.attributes.ai/request.actor_name"),
                accessor: "actor_name",
                Cell: ActorNameCell,
            },
            {
                Header: I18n.t("activerecord.attributes.ai/request.organization_name"),
                accessor: "organization_name",
                Cell: OrganizationNameCell,
            },
            {
                Header: I18n.t("activerecord.attributes.ai/request.created_at"),
                accessor: "created_at",
                Cell: CreatedAtCell,
            },
        ],
        [],
    );
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(({ pageIndex, sortBy }) => {
        const fetchId = ++fetchIdRef.current;
        const sort = sortBy[0] || { id: "created_at", desc: true };
        setLoading(true);

        getRequests(pageIndex + 1, sort.id, sort.desc ? "desc" : "asc").then((response) => {
            if (fetchId === fetchIdRef.current) {
                setData(response.data.records);
                setPageCount(response.data.page_count);
                setLoading(false);
            }
        });
    }, []);

    const renderRowSubComponent = useCallback(
        ({
            row: {
                original: { prompt },
            },
        }) => (
            <tr>
                <td colSpan={columns.length} className="bg-light">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <BootstrapTable borderless hover>
                                        <tbody>
                                            {PROMPT_FIELDS.map((field) => (
                                                <tr key={field}>
                                                    <td>{I18n.t(`activerecord.attributes.ai/prompt.${field}`)}</td>
                                                    <td>
                                                        {React.createElement(field === "message" ? "pre" : "span", {
                                                            className: "text-pre-wrap",
                                                            dangerouslySetInnerHTML: sanitizeText(
                                                                _toString(prompt[field]),
                                                                {
                                                                    allowedTags: [],
                                                                },
                                                            ),
                                                        })}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </BootstrapTable>
                                </Col>

                                <Col md={6}>
                                    <BootstrapTable borderless hover>
                                        <tbody>
                                            {RESPONSE_FIELDS.map((field) => (
                                                <tr key={field}>
                                                    <td>
                                                        {I18n.t(`activerecord.attributes.ai/request.response.${field}`)}
                                                    </td>
                                                    <td>
                                                        {React.createElement(field === "message" ? "pre" : "span", {
                                                            className: "text-pre-wrap",
                                                            dangerouslySetInnerHTML: sanitizeText(
                                                                _toString(prompt[field]),
                                                                {
                                                                    allowedTags: [],
                                                                },
                                                            ),
                                                        })}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </BootstrapTable>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </td>
            </tr>
        ),
        [],
    );

    return (
        <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            pageCount={pageCount}
            renderRowSubComponent={renderRowSubComponent}
            loading={loading}
            initialSortBy={[{ id: "created_at", desc: true }]}
        />
    );
};

ExpanderCell.propTypes = {
    row: PropTypes.shape({
        getToggleRowExpandedProps: PropTypes.func.isRequired,
        isExpanded: PropTypes.bool,
    }),
};
ActorNameCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            user_email: PropTypes.string.isRequired,
            user_name: PropTypes.string.isRequired,
        }),
    }),
};

OrganizationNameCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            organization_name: PropTypes.string.isRequired,
            organization_id: PropTypes.number.isRequired,
        }),
    }),
};

CreatedAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            created_at: PropTypes.string.isRequired,
        }),
    }),
};

export default RequestsTable;
