import React, { useCallback, useContext, useMemo, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import _join from "lodash/join";
import _map from "lodash/map";
import { useSelector } from "react-redux";
import I18n from "../../../../utils/i18n";
import Table from "./Table";
import { CreateProjectWizardContext } from "./CreateProjectWizardContext";
import { ActionButtonsCell, CellInput } from "./Cells";
import useDebounce from "../../../../utils/useDebounce";
import TooltipComponent from "../../../../common/components/TooltipComponent";
import { getTemplates } from "../../../../api/templatesApi";

const TemplatesTable = () => {
    const { selectedEvent, eventType, filterValue } = useContext(CreateProjectWizardContext);
    const currentWhitelabel = useSelector((state) => state.currentWhitelabel.resource);
    const defaultSortBy = { id: "template_info_number_of_uses", desc: true };
    const [activeId, setActiveId] = useState("favorites");
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const debouncedFilter = useDebounce(filterValue, 1500);
    const perPage = 5;

    const columns = useMemo(
        () => [
            {
                accessor: "id",
                isSorted: false,
                canFilter: false,
                Cell: CellInput,
            },
            {
                Header: I18n.t("home.create_event_wizard.templates_table.columns.name"),
                accessor: "name",
            },
            {
                Header: I18n.t("home.create_event_wizard.templates_table.columns.type"),
                accessor: "type",
            },
            {
                Header: I18n.t("home.create_event_wizard.templates_table.columns.number_of_uses"),
                accessor: "template_info_number_of_uses",
            },
            {
                id: "actionButtons",
                isSorted: false,
                Cell: (props) => (
                    <ActionButtonsCell {...props} showFavoriteButtons={true} currentWhitelabel={currentWhitelabel} />
                ),
            },
        ],
        [selectedEvent, eventType],
    );
    const filters = {
        favorites: { favorites: true },
        my_templates: { template_info_global_in: false },
        global_templates: { template_info_global_in: true },
    };

    const fetchData = useCallback(
        ({ pageIndex, sortBy }) => {
            const index = pageIndex + 1;
            const sort = sortBy[0] || defaultSortBy;
            setLoading(true);
            const ordering = sort.desc ? "desc" : "asc";
            const orderBy =
                sort.id === "owner_user_name" ? ["owner_user_first_name", "owner_user_last_name"] : [sort.id];

            getTemplates({
                q: {
                    tab_filter: {
                        ...filters[activeId],
                        legal: eventType === "legal",
                    },
                    filter_data: {
                        name_or_project_name_or_industry_name_or_user_display_name_cont:
                            debouncedFilter.name_or_project_name_or_industry_name_or_user_display_name_cont,
                    },
                    s: _join(
                        _map(orderBy, (key) => `${key} ${ordering}`),
                        ", ",
                    ),
                    page: index,
                    per_page: perPage,
                },
            })
                .then(({ data: { records, pages_count } }) => {
                    setData(records);
                    setPageCount(pages_count);
                })
                .catch(() => setData([]))
                .finally(() => setLoading(false));
        },
        [activeId, eventType, debouncedFilter.name_or_project_name_or_industry_name_or_user_display_name_cont],
    );

    const table = (key) =>
        key === activeId && (
            <div className="mt-3">
                <Table
                    columns={columns}
                    data={data}
                    fetchData={fetchData}
                    loading={loading}
                    pageCount={pageCount}
                    perPage={perPage}
                />
            </div>
        );

    const titleWithTooltip = (key) => (
        <>
            <span className="mr-1">{I18n.t(`home.templates.tabs.${key}.title`)}</span>
            <TooltipComponent content={I18n.t(`home.templates.tabs.${key}.tip`)} icon={faCircleExclamation} />
        </>
    );

    return (
        <Tabs activeKey={activeId} onSelect={setActiveId}>
            <Tab eventKey="favorites" title={titleWithTooltip("favorites")}>
                {table("favorites")}
            </Tab>
            <Tab title={titleWithTooltip("my_templates")} eventKey="my_templates">
                {table("my_templates")}
            </Tab>
            <Tab title={titleWithTooltip("global_templates")} eventKey="global_templates">
                {table("global_templates")}
            </Tab>
        </Tabs>
    );
};

export default TemplatesTable;
