import React, { useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { getIn, useField, useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlaneTop, faTrash } from "@fortawesome/pro-regular-svg-icons";
import _isEmpty from "lodash/isEmpty";
import I18n from "../../../../utils/i18n";
import TooltipComponent from "../../../../common/components/TooltipComponent";
import { sendPrompt } from "../../../../api/aiAPI";

const MessageControl = () => {
    const { values } = useFormikContext();
    const [field, meta] = useField("prompt.message");
    const [isSending, setIsSending] = useState(false);
    const [response, setResponse] = useState("");

    const handleClear = () => {
        field.onChange({
            target: {
                name: "prompt[message]",
                value: "",
            },
        });
    };

    const handleSend = () => {
        setIsSending(true);

        sendPrompt({ request: values })
            .then((data) => setResponse(data.response))
            .finally(() => setIsSending(false));
    };

    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <h4 className="mb-0">
                                {I18n.t("activerecord.attributes.ai/prompt.message")}
                                <TooltipComponent
                                    content={I18n.t("ai/prompt.message.tooltip", {
                                        user_message: null,
                                    })}
                                />
                            </h4>
                        </Card.Header>
                        <Card.Body>
                            <Form.Control
                                as="textarea"
                                name="prompt[message]"
                                rows={10}
                                value={field.value || ""}
                                placeholder={I18n.t("ai/prompt.message.placeholder")}
                                onChange={field.onChange}
                                isInvalid={getIn(meta.error, "prompt.message")}
                            />
                            <Form.Control.Feedback type="invalid">
                                {getIn(meta.error, "prompt.message")}
                            </Form.Control.Feedback>
                        </Card.Body>
                        <Card.Footer className="d-flex justify-content-end">
                            <Button
                                variant="outline-primary"
                                className="m-1"
                                title={I18n.t("ai/prompt.clear.title")}
                                onClick={handleClear}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                            <Button
                                variant="outline-primary"
                                className="m-1"
                                onClick={handleSend}
                                title={I18n.t("ai/prompt.send.title")}
                                disabled={isSending || _isEmpty(field.value || "")}
                            >
                                {isSending ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    <FontAwesomeIcon icon={faPaperPlaneTop} />
                                )}
                            </Button>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Row className="pt-3">
                <Col>
                    <Card>
                        <Card.Header>
                            <h4 className="mb-0">{I18n.t("activerecord.attributes.ai/prompt.response")}</h4>
                        </Card.Header>
                        <Card.Body>
                            <Form.Control as="textarea" rows={10} readOnly={true} value={response} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default MessageControl;
