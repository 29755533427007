import React from "react";
import PropTypes from "prop-types";
import I18n from "../../utils/i18n";
import FiltersBar from "../../common/components/DiversityPage/FiltersBar";
import {
    QuarterFilter,
    AttributesFilter,
    FirmsFilter,
    FirmSizesFilter,
    HireDateFilter,
    LeadershipsFilter,
    RolesFilter,
    ScoreFilter,
    StatusesFilter,
    ListFilter,
} from "../../common/components/DiversityPage/filters";
import TooltipComponent from "../../common/components/TooltipComponent";

const DiversityFirmwideFiltersBar = ({ setChartParams, getFilters, setFilter, setChangedFilters }) => (
    <FiltersBar
        filterTypes={[
            QuarterFilter,
            FirmsFilter,
            FirmSizesFilter,
            RolesFilter,
            AttributesFilter,
            StatusesFilter,
            LeadershipsFilter,
            HireDateFilter,
            ScoreFilter,
            ListFilter,
        ]}
        getFilters={getFilters}
        setChartParams={setChartParams}
        setFilter={setFilter}
        setChangedFilters={setChangedFilters}
    >
        <div className="d-inline-flex m-1 align-middle">
            <TooltipComponent
                content={I18n.t("diversity.tooltips.firmwide.top")}
                placement="left"
                trigger={["focus"]}
                size="lg"
            />
        </div>
    </FiltersBar>
);

DiversityFirmwideFiltersBar.propTypes = {
    getFilters: PropTypes.func.isRequired,
    setChartParams: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    setChangedFilters: PropTypes.func,
};

export default DiversityFirmwideFiltersBar;
