import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import I18n from "../../../utils/i18n";
import { sanitizeText } from "../../../utils/sanitizeText";

const CommentPopover = ({ requesterName, comment }) => {
    const commentTooltip = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">
                {I18n.t("tables.diversity/participation.titles.comment", { name: requesterName })}
            </Popover.Title>
            <Popover.Content>
                <div dangerouslySetInnerHTML={sanitizeText(comment)} className="text-pre-line" />
            </Popover.Content>
        </Popover>
    );

    return (
        <div className="pl-2">
            <OverlayTrigger trigger={["focus"]} placement="right" overlay={commentTooltip}>
                <a role="button" tabIndex={0}>
                    <FontAwesomeIcon icon={faCommentAlt} />
                </a>
            </OverlayTrigger>
        </div>
    );
};

CommentPopover.propTypes = {
    requesterName: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    customClassName: PropTypes.string,
};

export default CommentPopover;
