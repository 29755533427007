import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Container } from "react-bootstrap";
import I18n from "../../utils/i18n";
import RequestsTable from "./components/RequestsTable";

const AIRequestsPage = () => {
    const currentUser = useSelector((state) => state.currentUser.resource);
    const history = useHistory();

    useEffect(() => {
        if (!currentUser.superadmin && !currentUser.admin) {
            history.push("/");
        }
    }, []);

    return (
        <>
            <div className="scrollable d-flex flex-column">
                <div className="flex-grow-1 overflow-auto bg-white">
                    <Col>
                        <Container fluid className="py-3">
                            <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3">
                                <h2>{I18n.t("sidebar.links.manage.ai_requests")}</h2>
                            </div>
                            <RequestsTable />
                        </Container>
                    </Col>
                </div>
            </div>
        </>
    );
};

export default AIRequestsPage;
