import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/pro-solid-svg-icons";
import _reject from "lodash/reject";
import Table from "../../../common/components/Table";
import ThreeDotsToggle from "../../../common/components/Table/ThreeDotsToggle";
import I18n from "../../../utils/i18n";
import { getPrompts, deletePrompt } from "../../../api/aiAPI";
import DeleteAction from "../../../common/components/DeleteAction";

const ActionsCell = ({
    row: {
        original: { id },
    },
    updateData,
}) => {
    const [deleteModelShow, setDeleteModalShow] = useState(false);

    const onDeleteAttorneySubmit = (_event, { setIsSubmitting }) => {
        deletePrompt(id).then(() => {
            updateData(id);
            setDeleteModalShow(false);
            setIsSubmitting(false);
        });
    };

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle as={ThreeDotsToggle} />
                <Dropdown.Menu size="sm" title="">
                    <Dropdown.Item as={Link} to={`/ai/prompts/${id}`}>
                        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                        {I18n.t("common.links.edit")}
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} onClick={() => setDeleteModalShow(true)} to="#">
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                        {I18n.t("common.links.delete")}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <DeleteAction
                resource={I18n.t("activerecord.models.ai/prompt.one")}
                onSubmit={onDeleteAttorneySubmit}
                show={deleteModelShow}
                onHide={() => setDeleteModalShow(false)}
            />
        </>
    );
};

const NameCell = ({
    row: {
        original: { name, uid },
    },
}) => (
    <div className="d-flex flex-column">
        {name}
        <small className="text-muted">{uid}</small>
    </div>
);

const UpdatedAtCell = ({
    row: {
        original: { updated_at },
    },
}) => <div className="d-flex flex-column">{I18n.l("time.formats.long", updated_at)}</div>;

const PromptsTable = () => {
    const columns = useMemo(
        () => [
            {
                Header: I18n.t("activerecord.attributes.ai/prompt.name"),
                accessor: "name",
                Cell: NameCell,
            },
            {
                Header: I18n.t("activerecord.attributes.ai/prompt.ai_model_id"),
                accessor: "ai_model_name",
            },
            {
                Header: I18n.t("activerecord.attributes.ai/prompt.updated_at"),
                accessor: "updated_at",
                Cell: UpdatedAtCell,
            },
            {
                Header: I18n.t("activerecord.models.whitelabel"),
                accessor: "whitelabel_name",
            },
            {
                Header: () => null,
                id: "actions",
                isSorted: false,
                Cell: (props) => <ActionsCell {...props} updateData={updateData} />,
            },
        ],
        [],
    );

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);

    const fetchData = useCallback(({ pageIndex, sortBy }) => {
        const fetchId = ++fetchIdRef.current;
        const sort = sortBy[0] || { id: "updated_at", desc: true };
        setLoading(true);

        getPrompts(pageIndex + 1, sort.id, sort.desc ? "desc" : "asc").then((response) => {
            if (fetchId === fetchIdRef.current) {
                setData(response.data.records);
                setPageCount(response.data.page_count);
                setLoading(false);
            }
        });
    }, []);

    const updateData = (id) => setData((prompts) => _reject(prompts, ["id", id]));

    return (
        <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            pageCount={pageCount}
            loading={loading}
            initialSortBy={[{ id: "updated_at", desc: true }]}
        />
    );
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
    }),
    updateData: PropTypes.func.isRequired,
};

NameCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            uid: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    }),
};

UpdatedAtCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            updated_at: PropTypes.string.isRequired,
        }),
    }),
};

export default PromptsTable;
