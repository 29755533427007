import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import Chart from "react-chartjs-2";
import dataLabelsPlugin from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import "chartjs-plugin-doughnutlabel";
import "chartjs-chart-geo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt as fasCommentAlt } from "@fortawesome/pro-solid-svg-icons";
import { faCommentAlt as farCommentAlt } from "@fortawesome/pro-regular-svg-icons";
import _ from "lodash";
import classNames from "classnames";
import I18n from "../../../utils/i18n";
import TooltipComponent from "../TooltipComponent";

export function getShortLabelForValue(value) {
    const limit = 5;
    const label = value;
    if (label.length <= limit) return label;

    const postfix = label.match(/\(\d+\)/) || "";
    const substrLimit = label.replace(postfix, "").trim().substring(limit).search(/\W/);
    if (substrLimit < 0) return label;

    const prefix = label.substring(0, limit + substrLimit);
    return `${prefix}... ${postfix}`.trim();
}

export function getKLabelForValue(value) {
    return Math.abs(value) > 999
        ? (Math.sign(value) * (Math.abs(value) / 1000)).toLocaleString(I18n.locale) + "k"
        : Math.sign(value) * Math.abs(value);
}

const ChartComponent = ({
    title,
    subtitle,
    tooltip,
    type,
    data,
    options,
    useDataLabels = false,
    useTooltips = false,
    canvasRefs,
    inProgress = false,
    position,
}) => {
    const [displayDataLabels, setDisplayDataLabels] = useState(false);

    const dataLabelsOptions = {
        plugins: {
            datalabels: {
                display: displayDataLabels ? "auto" : false,
                align: "end",
                anchor: "end",
                offset: 16,
                backgroundColor: (context) => context.dataset.backgroundColor,
                borderRadius: 4,
                color: "white",
                padding: 6,
                formatter: (value, context) =>
                    context.chart.data.datasets[context.datasetIndex].data[context.dataIndex].firm_name,
            },
        },
    };

    const legendOptions = {
        legend: {
            display: !displayDataLabels,
        },
    };

    const layoutOptions =
        displayDataLabels && type === "doughnut"
            ? {
                  layout: {
                      padding: {
                          top: 50,
                          bottom: 50,
                          left: 200,
                          right: 200,
                      },
                  },
              }
            : {
                  layout: {
                      padding: {
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                      },
                  },
              };

    const addCanvasToRef = (chart) => {
        canvasRefs ? (canvasRefs.current[title] = { content: chart, position: position }) : null;
    };

    return (
        <Card className={classNames("mt-3", { "bg-light": inProgress })}>
            <Card.Body>
                <Card.Title as="h4">
                    {title}
                    {useTooltips && <TooltipComponent content={tooltip} />}
                    {useDataLabels && (
                        <FontAwesomeIcon
                            icon={displayDataLabels ? fasCommentAlt : farCommentAlt}
                            className="float-right"
                            title={I18n.t("diversity.buttons.toggle_datalabels")}
                            onClick={() => setDisplayDataLabels(!displayDataLabels)}
                        />
                    )}
                </Card.Title>
                <Card.Subtitle as="h6">{subtitle}</Card.Subtitle>
                <Chart
                    ref={addCanvasToRef}
                    type={type}
                    data={data}
                    options={_.merge(dataLabelsOptions, legendOptions, layoutOptions, options, {
                        responsive: true,
                        maintainAspectRatio: false,
                    })}
                    plugins={[dataLabelsPlugin, annotationPlugin]}
                />
            </Card.Body>
        </Card>
    );
};

ChartComponent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    tooltip: PropTypes.string,
    type: PropTypes.string.isRequired,
    data: PropTypes.shape({
        labels: PropTypes.array,
        datasets: PropTypes.array,
    }),
    options: PropTypes.object,
    useDataLabels: PropTypes.bool,
    useTooltips: PropTypes.bool,
    canvasRefs: PropTypes.object,
    inProgress: PropTypes.bool,
    position: PropTypes.number.isRequired,
};

export default ChartComponent;
