import React from "react";
import PropTypes from "prop-types";
import { Col, Form, Row } from "react-bootstrap";
import { getIn, useField } from "formik";
import I18n from "../../../../utils/i18n";

const RangeControl = ({ name, min = 0, max = 1, step = 0.1 }) => {
    const [field, meta] = useField(`prompt.${name}`);

    return (
        <Row>
            <Col>
                <Form.Label column={name}>{I18n.t(`activerecord.attributes.ai/prompt.${name}`)}</Form.Label>
                <div className="d-flex justify-content-between align-items-center">
                    <Form.Label column={name}>{min}</Form.Label>
                    <Form.Control
                        custom
                        type="range"
                        step={step}
                        min={min}
                        max={max}
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                    />
                    <Form.Label column="temperature">{max}</Form.Label>
                </div>
            </Col>
            <Col md={3} className="align-self-end">
                <Form.Control
                    type="number"
                    step={step}
                    min={min}
                    max={max}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={getIn(meta.error, field.name)}
                />
                <Form.Control.Feedback type="invalid">{getIn(meta.error, field.name)}</Form.Control.Feedback>
            </Col>
        </Row>
    );
};

RangeControl.propTypes = {
    name: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
};

export default RangeControl;
