import sanitizeHtml from "sanitize-html";

export function sanitizeText(text, options = { allowedTags: ["b", "p", "br", "small"] }) {
    return { __html: sanitizeHtml(text, options) };
}

export function sanitizeLink(text) {
    return { __html: sanitizeHtml(text, { allowedTags: ["a"] }) };
}

export const sanitizeFormattedText = (text) =>
    sanitizeHtml(text, {
        allowedTags: [
            "em",
            "strong",
            "s",
            "p",
            "br",
            "span",
            "ul",
            "ol",
            "li",
            "table",
            "tbody",
            "td",
            "tfoot",
            "th",
            "thead",
            "tr",
            "a",
            "sub",
            "sup",
            "col",
            "colgroup",
        ],
        allowedAttributes: {
            p: ["style"],
            span: ["style"],
            table: ["style", "border"],
            td: ["style"],
            col: ["style"],
            colgroup: ["style"],
            a: ["title", "href", "style", "target"],
        },
    });
