import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { SidebarWithLayout } from "@justicebid/react-jb-sidebar";
import PrivateRoute from "../../components/PrivateRoute";
import { AppLoader, DismissibleAlert } from "../../components";
import {
    EditOrganizationPage,
    EditUserProfilePage,
    HomePage,
    SwitchOrganizationPage,
    OrganizationsPage,
    TermsAndConditionsPage,
    PrivacyPolicyPage,
    OecFaqPage,
    OecPrivacyFaqPage,
    WhitelabelsPage,
    EditWhitelabelPage,
    NewDiversityCertificatePage,
    NewDiversityCertificateTypePage,
    NewOrganizationPage,
    NewPublicDomainsPage,
    NewTieredDiversitySupplierPage,
    NewUserPage,
    DiversityFirmwidePage,
    DiversityFirmwideDashboardPage,
    DiversityFirmwideUploadPage,
    DiversityFirmwideMirrorPage,
    DiversityClientSpecificPage,
    DiversityClientSpecificUploadPage,
    DiversityClientSpecificReportsPage,
    DiversityClientSpecificMirrorPage,
    DiversityClientSpecificProgressPage,
    DiversityKeyIndicatorsPage,
    DiversityFirmwideRequestsPage,
    DiversityFirmwideGlobalRequestsPage,
    DiversityClientSpecificGlobalRequestsPage,
    DiversityClientSpecificRequestsPage,
    DiversityCertificateTypesPage,
    DiversityClientSpecificDashboardPage,
    DiversityClientSpecificProgressDashboardPage,
    DiversityClientSpecificMirrorDashboardPage,
    SingInAsPage,
    PublicDomainsPage,
    PasswordExpiredPage,
    CompanyProfilePage,
    CompanyProfilesPage,
    TieredDiversityDashboardPage,
    TieredDiversityRequestsPage,
    TieredDiversityStarterPage,
    TieredDiversityPricingPage,
    EditPublicDomainsPage,
    EditTwoFactorAuthenticationPage,
    EditTieredDiversityCertificatePage,
    EditDiversityCertificatePage,
    EditDiversityCertificateTypePage,
    WizardPage,
    NewLocationPage,
    EditLocationPage,
    DiversityFirmwideMirrorDashboardPage,
    DiversityClientSpecificReportsDashboardPage,
    CompanyListsPage,
    NewLegalAttorneyPage,
    EditLegalAttorneyPage,
    NewLegalCasePage,
    EditLegalCasePage,
    ActivityLogsPage,
    FAQPage,
    FAQManagePage,
    FAQNewItemPage,
    FAQEditItemPage,
    AIPromptPage,
    AIPromptsPage,
    AIRequestsPage,
} from "../../../pages";
import { fetchCurrentActor } from "../../../state/currentActor/currentActorActions";
import I18n from "../../../utils/i18n";
import TieredDiversityRoute from "../../components/TieredDiversityRoute";
import ExternalRoute from "../../components/ExternalRoute";
import { getSidebar } from "../../../api/sidebarApi";

const LayoutWithSidebarContainer = ({ currentActor, fetchCurrentActor }) => {
    useEffect(fetchCurrentActor, []);

    if (currentActor.fetching) {
        return <AppLoader />;
    }

    return (
        <div className="page-wrapper">
            <Router>
                <SidebarWithLayout getSidebar={getSidebar}>
                    <DismissibleAlert />

                    <Switch>
                        <PrivateRoute exact path="/">
                            <HomePage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/select_organizations">
                            <SwitchOrganizationPage />
                        </PrivateRoute>
                        <PrivateRoute
                            exact
                            path="/organizations/:organizationUid/users/:actorUid/edit"
                            renderer={(props) => <EditUserProfilePage key={props.match.params.actorUid} {...props} />}
                        />
                        <PrivateRoute
                            exact
                            path="/users/:actorUid/edit"
                            renderer={(props) => <EditUserProfilePage key={props.match.params.actorUid} {...props} />}
                        />
                        <PrivateRoute exact path="/organizations/wizard">
                            <WizardPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/organizations/new">
                            <NewOrganizationPage title={I18n.t("activerecord.models.organization")} />
                        </PrivateRoute>
                        <PrivateRoute
                            exact
                            path="/organizations/:uid/:tab?"
                            renderer={(props) => <EditOrganizationPage key={props.match.params.uid} {...props} />}
                        />
                        <PrivateRoute exact path="/organizations/:parentId/organizations/new">
                            <NewOrganizationPage title={I18n.t("forms.sub_organization.title")} />
                        </PrivateRoute>
                        <PrivateRoute exact path="/organizations/:organization_uid/locations/new">
                            <NewLocationPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/organizations/:organization_uid/locations/:id/edit">
                            <EditLocationPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/organizations/:organization_uid/legal/attorneys/new">
                            <NewLegalAttorneyPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/organizations/:organization_uid/legal/attorneys/:id/edit">
                            <EditLegalAttorneyPage />
                        </PrivateRoute>
                        <PrivateRoute
                            exact
                            path="/organizations/:organization_uid/legal/attorneys/:attorney_id/cases/new"
                        >
                            <NewLegalCasePage />
                        </PrivateRoute>
                        <PrivateRoute
                            exact
                            path="/organizations/:organization_uid/legal/attorneys/:attorney_id/cases/:id/edit"
                        >
                            <EditLegalCasePage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/admins/sign_in">
                            <SingInAsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/organizations">
                            <OrganizationsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/whitelabels">
                            <WhitelabelsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/whitelabels/:domain/edit">
                            <EditWhitelabelPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/public_domains">
                            <PublicDomainsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/public_domains/new">
                            <NewPublicDomainsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/public_domains/:id/edit">
                            <EditPublicDomainsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/firmwide/requests/global">
                            <DiversityFirmwideGlobalRequestsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/firmwide/requests">
                            <DiversityFirmwideRequestsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/terms_and_conditions">
                            <TermsAndConditionsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/privacy_policy">
                            <PrivacyPolicyPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/oec_faq">
                            <OecFaqPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/oec_privacy_faq">
                            <OecPrivacyFaqPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/organizations/:organization_uid/users/new">
                            <NewUserPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/companies/profiles">
                            <CompanyProfilesPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/companies/lists">
                            <CompanyListsPage />
                        </PrivateRoute>
                        <PrivateRoute
                            exact
                            path="/companies/profiles/:uid"
                            renderer={(props) => <CompanyProfilePage key={props.match.params.uid} {...props} />}
                        />
                        <PrivateRoute exact path="/organizations/:uid/diversity/certificates/new">
                            <NewDiversityCertificatePage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/organizations/:uid/diversity/certificates/:certificateId/edit">
                            <EditDiversityCertificatePage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/dashboards/firmwide">
                            <DiversityFirmwidePage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/firmwide">
                            <DiversityFirmwideUploadPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/client_specific">
                            <DiversityClientSpecificUploadPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/dashboards/client_specific">
                            <DiversityClientSpecificPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/dashboards/firmwide_mirror">
                            <DiversityFirmwideMirrorPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/client_specific/requests/global">
                            <DiversityClientSpecificGlobalRequestsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/client_specific/requests">
                            <DiversityClientSpecificRequestsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/client_specific/key_indicators">
                            <DiversityKeyIndicatorsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/dashboards/client_specific_reports">
                            <DiversityClientSpecificReportsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/dashboards/client_specific_mirror">
                            <DiversityClientSpecificMirrorPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/dashboards/client_specific_progress">
                            <DiversityClientSpecificProgressPage />
                        </PrivateRoute>
                        <TieredDiversityRoute exact path="/tiered_diversity/dashboard">
                            <TieredDiversityDashboardPage />
                        </TieredDiversityRoute>
                        <TieredDiversityRoute exact path="/tiered_diversity/requests">
                            <TieredDiversityRequestsPage />
                        </TieredDiversityRoute>
                        <PrivateRoute exact path="/tiered_diversity/starter">
                            <TieredDiversityStarterPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/tiered_diversity/pricing">
                            <TieredDiversityPricingPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/dashboards/client_specific/edit">
                            <DiversityClientSpecificDashboardPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/dashboards/client_specific_mirror/edit">
                            <DiversityClientSpecificMirrorDashboardPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/dashboards/firmwide/edit">
                            <DiversityFirmwideDashboardPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/dashboards/firmwide_mirror/edit">
                            <DiversityFirmwideMirrorDashboardPage />
                        </PrivateRoute>
                        <PrivateRoute exect path="/diversity/dashboards/client_specific_progress/edit">
                            <DiversityClientSpecificProgressDashboardPage />
                        </PrivateRoute>
                        <PrivateRoute exect path="/diversity/dashboards/client_specific_reports/edit">
                            <DiversityClientSpecificReportsDashboardPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/tiered_diversity/partnerships/new">
                            <NewTieredDiversitySupplierPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/tiered_diversity/partnerships/:partnership_id/certificate/new">
                            <EditTieredDiversityCertificatePage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/tiered_diversity/partnerships/:partnership_id/certificate/edit">
                            <EditTieredDiversityCertificatePage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/users/two_factor_authentication">
                            <EditTwoFactorAuthenticationPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/certificate_types">
                            <DiversityCertificateTypesPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/certificate_types/:certificateTypeId/edit">
                            <EditDiversityCertificateTypePage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/diversity/certificate_types/new">
                            <NewDiversityCertificateTypePage />
                        </PrivateRoute>
                        <Route exact path="/users/password_expired">
                            <PasswordExpiredPage />
                        </Route>
                        <PrivateRoute exact path="/activity/logs">
                            <ActivityLogsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/faq">
                            <FAQPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/manage_faq">
                            <FAQManagePage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/faq/items/new">
                            <FAQNewItemPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/faq/items/:id">
                            <FAQEditItemPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/ai/prompts">
                            <AIPromptsPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/ai/prompts/new">
                            <AIPromptPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/ai/prompts/:id">
                            <AIPromptPage />
                        </PrivateRoute>
                        <PrivateRoute exact path="/ai/requests">
                            <AIRequestsPage />
                        </PrivateRoute>
                        <ExternalRoute />
                        <PrivateRoute renderer={() => <Redirect to="/" />} />
                    </Switch>
                </SidebarWithLayout>
            </Router>
        </div>
    );
};

const mapStateToProps = (state) => ({
    currentActor: state.currentActor,
    currentWhitelabel: state.currentWhitelabel.resource,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCurrentActor: () => dispatch(fetchCurrentActor()),
});

LayoutWithSidebarContainer.propTypes = {
    currentWhitelabel: PropTypes.shape({
        hosts: PropTypes.shape({
            bid: PropTypes.string,
            messaging: PropTypes.string,
        }),
    }),
    currentActor: PropTypes.shape({
        fetching: PropTypes.bool.isRequired,
        resource: PropTypes.object,
    }),
    fetchCurrentActor: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            uid: PropTypes.string,
            actorUid: PropTypes.string,
        }),
    }),
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutWithSidebarContainer);
