import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Container, Row, Col, FormControl, FormLabel, FormCheck } from "react-bootstrap";
import _map from "lodash/map";
import _last from "lodash/last";
import moment from "moment";
import TooltipComponent from "../../../common/components/TooltipComponent";
import I18n from "../../../utils/i18n";
import { convertMomentToQuarterFormat } from "../../../utils/diversityQuarters";

const SetupStep = ({ periodicity, setPeriodicity, period, setPeriod }) => {
    const currentUser = useSelector((state) => state.currentUser.resource);

    const getPeriodValues = (startDate, endDate) => {
        let result = [];

        for (let currentDate = startDate; currentDate <= endDate; currentDate.add(1, "quarter")) {
            result.push(currentDate.clone());
        }

        return result;
    };

    const getQuarterlyPeriodValues = () => {
        let startDate = moment().tz(currentUser.time_zone).startOf("quarter").subtract(10, "quarter");
        let endDate = moment().tz(currentUser.time_zone).startOf("quarter").subtract(1, "quarter");

        return getPeriodValues(startDate, endDate);
    };

    const getAnnuallyFromPeriodValues = () => {
        let startDate = moment().tz(currentUser.time_zone).startOf("quarter").subtract(10, "quarter");
        let endDate = moment().tz(currentUser.time_zone).startOf("quarter").subtract(4, "quarter");

        return getPeriodValues(startDate, endDate);
    };

    const getAnnuallyToPeriodValues = () => {
        let startDate = moment().tz(currentUser.time_zone).startOf("quarter").subtract(7, "quarter");
        let endDate = moment().tz(currentUser.time_zone).startOf("quarter").subtract(1, "quarter");

        return getPeriodValues(startDate, endDate);
    };

    const getFromPeriodValues = () => {
        if (periodicity === "annually") {
            return getAnnuallyFromPeriodValues();
        } else {
            return getQuarterlyPeriodValues();
        }
    };

    const getToPeriodValues = () => {
        if (periodicity === "annually") {
            return getAnnuallyToPeriodValues();
        } else {
            return getQuarterlyPeriodValues();
        }
    };

    useEffect(
        () =>
            setPeriod({
                from: _last(getFromPeriodValues()).unix(),
                to: _last(getToPeriodValues()).unix(),
            }),
        [periodicity],
    );

    return (
        <Container fluid className="d-flex flex-column py-3">
            <Row className="pt-3 pb-2 border-bottom">
                <h2>{I18n.t("tiered_diversity.suppliers.new.steps.setup.title")}</h2>
            </Row>
            <Row className="flex-grow-1">
                <Col className="p-3">
                    <Row className="align-items-end">
                        <Col md={4} className="d-flex">
                            <FormLabel>
                                {I18n.t("tiered_diversity.suppliers.new.steps.setup.periodicity.label")}
                            </FormLabel>
                            <TooltipComponent
                                content={I18n.t("tiered_diversity.suppliers.new.steps.setup.periodicity.tooltip")}
                            />
                        </Col>
                        <Col>
                            <FormCheck
                                type="radio"
                                label={I18n.t("tiered_diversity.suppliers.new.steps.setup.periodicity.quarterly")}
                                name="periodicity"
                                id="periodicity_quarterly"
                                value="quarterly"
                                checked={periodicity === "quarterly"}
                                onChange={(event) => setPeriodicity(event.target.value)}
                            />
                            <FormCheck
                                type="radio"
                                label={I18n.t("tiered_diversity.suppliers.new.steps.setup.periodicity.annually")}
                                name="periodicity"
                                id="periodicity_annually"
                                value="annually"
                                checked={periodicity === "annually"}
                                onChange={(event) => setPeriodicity(event.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col md={4} className="d-flex">
                            <FormLabel>{I18n.t("tiered_diversity.suppliers.new.steps.setup.period.label")}</FormLabel>
                            <TooltipComponent
                                content={I18n.t("tiered_diversity.suppliers.new.steps.setup.period.tooltip")}
                            />
                        </Col>
                        <Col>
                            <Row className="align-items-end p-1">
                                <Col md={2}>
                                    <FormLabel>
                                        {I18n.t("tiered_diversity.suppliers.new.steps.setup.period.from")}
                                    </FormLabel>
                                </Col>
                                <Col md={4}>
                                    <FormControl
                                        as="select"
                                        custom
                                        value={period.from}
                                        onChange={(event) =>
                                            setPeriod((prevState) => ({
                                                from: parseInt(event.target.value),
                                                to:
                                                    periodicity === "annually"
                                                        ? moment
                                                              .tz(
                                                                  moment.unix(event.target.value),
                                                                  currentUser.time_zone,
                                                              )
                                                              .startOf("quarter")
                                                              .add(3, "quarter")
                                                              .unix()
                                                        : parseInt(prevState.to),
                                            }))
                                        }
                                    >
                                        {_map(getFromPeriodValues(), (value) => (
                                            <option key={value.unix()} value={value.unix()}>
                                                {value.format("[Q]Q[']YYYY")}
                                            </option>
                                        ))}
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row className="align-items-end p-1">
                                <Col md={2}>
                                    <FormLabel>
                                        {I18n.t("tiered_diversity.suppliers.new.steps.setup.period.to")}
                                    </FormLabel>
                                </Col>
                                <Col md={4}>
                                    <FormControl
                                        as="select"
                                        custom
                                        value={period.to}
                                        disabled={periodicity === "annually"}
                                        onChange={(event) =>
                                            setPeriod((prevState) => ({
                                                ...prevState,
                                                to: parseInt(event.target.value),
                                            }))
                                        }
                                    >
                                        {_map(getToPeriodValues(periodicity), (value) => (
                                            <option key={value.unix()} value={value.unix()}>
                                                {convertMomentToQuarterFormat(value)}
                                            </option>
                                        ))}
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row className="align-items-end p-1">
                                <Col>
                                    <FormLabel>
                                        {I18n.t("tiered_diversity.suppliers.new.steps.setup.period.description")}
                                    </FormLabel>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

SetupStep.propTypes = {
    periodicity: PropTypes.string.isRequired,
    setPeriodicity: PropTypes.func.isRequired,
    period: PropTypes.shape({
        from: PropTypes.number.isRequired,
        to: PropTypes.number.isRequired,
    }),
    setPeriod: PropTypes.func.isRequired,
};

export default SetupStep;
