import API from "../utils/api";

export function getPrompt(id = undefined) {
    if (id === undefined) {
        return API.get("/ai/prompts/new");
    } else {
        return API.get(`/ai/prompts/${id}/edit`);
    }
}

export function getPrompts(page, column, order) {
    return API.get("/ai/prompts", { params: { page: page, column: column, order: order } });
}

export function getRequests(page, column, order) {
    return API.get("/ai/requests", { params: { page: page, column: column, order: order } });
}

export function savePrompt(data) {
    const id = data.prompt.id;

    if (id) {
        return updatePrompt(id, data);
    }

    return createPrompt(data);
}

export function createPrompt(data) {
    return API.post("/ai/prompts", data);
}

export function updatePrompt(id, data) {
    return API.put(`/ai/prompts/${id}`, data);
}

export function deletePrompt(id) {
    return API.delete(`/ai/prompts/${id}`);
}

export function getModels() {
    return API.get("/ai/models");
}

export function sendPrompt(data) {
    return API.post("/ai/requests", data);
}
